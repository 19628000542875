import { ascending } from 'd3'
import { accessObjectPropertyValue } from '../../../../helpers/data/objects.js'
import dayjs from 'dayjs'

/**
 * Timeseries from received dates
 * @param {*} array
 * @returns
 */
export function makeTimeseriesFromData (array) {
  const targetProperty = ['group', 'month']
  const points = new Set()

  array?.forEach(function (one) {
    const oneValue = accessObjectPropertyValue(targetProperty, one)
    points.add(oneValue)
  })

  const pointsArray = Array.from(points)

  pointsArray.sort(function (p1, p2) {
    return ascending(p1, p2)
  })

  return pointsArray
}

export function makeTimeseries (context) {
  const timeseriesArray = []

  const startDate = dayjs(context.dates.min)
  const endDate = dayjs(context.dates.max)

  let currentDate = startDate.startOf('month')
  while (currentDate.isSameOrBefore(endDate)) {
    const monthString = currentDate.format('YYYY-MM') // Gets YYYY-MM
    timeseriesArray.push(monthString)

    // Move to next month
    currentDate = currentDate.add(1, 'month')
  }

  return timeseriesArray
}
