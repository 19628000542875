import { callApi } from '../../../helpers/fetch.js'

export async function disableUser (inviteId) {
  const apiUrl = '/api/invite/:inviteId'
  const data = await callApi(apiUrl, {
    inviteId
  }, {
    method: 'DELETE'
  })

  console.log(data)
}
