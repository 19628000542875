import { timeDuration } from '../../../../../helpers/helpers/time.js'
import { timestampFormatter } from '../helpers/timestamp-formatter.js'
import { generateAdditionalColumnDefs } from '../helpers/include-additional-columns.js'

export function makeColumnDefs (modulesData) {
  // console.log('makeColumnDefs', modulesData)

  const columnDefs = []

  columnDefs.push({
    field: 'name',
    pinned: 'left'
  })
  columnDefs.push({
    headerName: 'Latest Step',
    field: 'currentStep',
    pinned: 'left'
  })
  columnDefs.push({
    field: 'startedAt',
    valueFormatter: timestampFormatter
  })
  columnDefs.push({
    field: 'latestStepAt',
    valueFormatter: timestampFormatter
  })
  columnDefs.push({
    field: 'endedAt',
    valueFormatter: timestampFormatter
  })
  columnDefs.push({
    field: 'duration',
    valueGetter: function (cellParams) {
      const duration = timeDuration(cellParams.data?.startedAt, cellParams.data?.endedAt)
      return duration?.timeText
    }
  })
  columnDefs.push({
    field: 'loops',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'apiCalls',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'numberReceived',
    type: 'numericColumn'
  })
  columnDefs.push({
    field: 'numberUpdated',
    type: 'numericColumn'
  })

  // Other properties
  const baseProperties = [
    'name',
    'currentStep',
    'startedAt',
    'latestStepAt',
    'endedAt',
    'duration',
    'loops',
    'apiCalls',
    'numberReceived',
    'numberUpdated'
  ]

  const additionalColumnDefs = generateAdditionalColumnDefs(modulesData, baseProperties)
  columnDefs.push(...additionalColumnDefs)

  return columnDefs
}
