
// Page Module
import { fillString } from '../../../../helpers/templating/string-template.js'
import { appUser } from '../../../modules/user/user.js'
import { addListeners } from './listeners.js'
import htmlUserShowOne from './user-list-one.html'
import htmlUserSupport from './user-list-support.html'

export async function showUserList (accountData) {
  console.log('showUserList', accountData)
  const containerElement = document.getElementById('userList')
  containerElement.innerText = ''

  accountData._userAccounts?.forEach(function (oneAccountUser) {
    // Do not show the invitations here
    if (!oneAccountUser._user) return

    const userElement = showOneUser(oneAccountUser)
    containerElement.insertAdjacentHTML('beforeend', userElement)
  })

  addListeners(containerElement)
}

/**
 *
 * @param {*} oneAccountUser
 * @returns
 */
function showOneUser (oneAccountUser) {
  console.log('showOneUser', oneAccountUser)
  console.log('appUser.userData()?._id', appUser.userData()?._id)
  console.log(oneAccountUser._user?._id === appUser.userData()._id)

  if (oneAccountUser.isSupport) {
    return htmlUserSupport
  }

  const isCurrentUser = oneAccountUser._user?._id === appUser.userData()?._id

  const disableButton = fillString('<div class="button is-small is-warning" data-action="disable" data-user-id=":userId" data-invite-id=":inviteId">Disable</div>', {
    userId: oneAccountUser._user?._id,
    inviteId: oneAccountUser?._id
  })

  // If current user, do not show the disable button
  return fillString(htmlUserShowOne, {
    userEmail: oneAccountUser._user?.email || '',
    userName: oneAccountUser._user?.name,
    userRights: oneAccountUser.rights.join(', '),
    tagCurrentUser: isCurrentUser ? '<span class="tag is-info">This is you</span>' : '',
    disableButton: isCurrentUser ? '' : disableButton
  })
}
