import dayjs from 'dayjs'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { makeXeroLink } from '../../../../helpers/xero-links.js'
import { ascending } from 'd3'

export default function makeColumnDefsForAccounts (context) {
  const columnDefs = []

  const timeframeCoverage = computeTimeframeCoverage(context)

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      const coaAccount = cellParams.data?.chartOfAccount
      if (!coaAccount) return

      return coaAccount.statement
    },
    pinned: 'left'
  })

  // Chart of Account
  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      const coaAccount = cellParams.data?.chartOfAccount
      if (!coaAccount) return

      return ['[', coaAccount.code, ']', ' ', coaAccount.name].join('')
    },
    cellRenderer: function (cellParams) {
      const outputTemplate = ':accountText [<a href=":urlToXero" target="_blank">Xero</a>]'

      // TODO: manage when multiple entities
      const shortCode = getEntityShortcode(cellParams.context?.accountData, context.showOrgId)

      const urlToXero = makeXeroLink('ReportAccountTransactions', {
        shortCode,
        xeroAccountId: cellParams.data.chartOfAccount?.xeroAccountId,
        fromISOdate: timeframeCoverage.from,
        toISOdate: timeframeCoverage.to
      })

      return fillString(outputTemplate, {
        accountText: cellParams.value,
        urlToXero
      })
    },
    width: 200,
    pinned: 'left'
  })

  const monthsColumns = makeMonthsColumns(timeframeCoverage.monthsArray)
  columnDefs.push(...monthsColumns)

  return columnDefs
}

function computeTimeframeCoverage (context) {
  const journalsData = context.data?.journalsData

  const months = new Set()
  journalsData?.forEach(function (oneEntry) {
    const month = dayjs(oneEntry.journalDate).format('YYYY-MM')
    months.add(month)
  })

  const monthsArray = Array.from(months)
  monthsArray?.sort(function (m1, m2) {
    return ascending(m1, m2)
  })

  const timeframeCoverage = {
    from: dayjs(monthsArray[0]).startOf('month').format('YYYY-MM-DD'),
    to: dayjs(monthsArray[monthsArray.length - 1]).endOf('month').format('YYYY-MM-DD'),
    monthsArray
  }

  return timeframeCoverage
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeMonthsColumns (monthsArray) {
  const monthColDefs = []
  monthsArray?.forEach(function (oneMonth) {
    const colDef = {
      headerName: dayjs(oneMonth).format('MMM YYYY'),
      context: {
        month: oneMonth
      },
      type: 'numericColumn',
      valueGetter: function (cellParams) {
        const targetMonth = cellParams.colDef.context.month

        const cellValues = cellParams.data.values?.filter(function (value) {
          const valueMonth = dayjs(value.journalDate).format('YYYY-MM')
          return valueMonth === targetMonth
        })
        if (!cellValues) return null

        let value = 0
        cellValues.forEach(function (cellValue) {
          value += cellValue.journalLine?.netAmount || 0
        })

        return value
      },
      valueFormatter: function (cellParams) {
        if (!cellParams.value) return null

        return formatNumber(cellParams.value, 2)
      }
    }
    monthColDefs.push(colDef)
  })

  return monthColDefs
}

function getEntityShortcode (accountData, orgId) {
  const targetAccountOrg = accountData._accountOrganisations.find(function (one) {
    return one._xeroOrganisation?._id === orgId
  })
  const targetEntity = targetAccountOrg?._xeroOrganisation
  const shortCode = targetEntity.details?.shortCode

  return shortCode
}
