
// Page Module
import { fillString } from '../../../../helpers/templating/string-template.js'
import { callApi } from '../../../helpers/fetch.js'
import htmlInviteShowOne from './invite-list-one.html'

export async function showInviteList (accountData) {
  const containerElement = document.getElementById('invitesList')
  containerElement.innerText = ''

  const invitations = await loadList(accountData)
  invitations?.forEach(function (oneInvite) {
    const inviteElemenet = showOneInvite(accountData, oneInvite)
    containerElement.insertAdjacentHTML('beforeend', inviteElemenet)
  })

  if (!invitations || invitations.length === 0) {
    containerElement.innerHTML = '<div class="notification is-info">There are no ongoing invitations</div>'
  }
}

async function loadList (accountData) {
  const apiUrl = '/api/invite/list/byaccount/:accountId'

  const data = await callApi(apiUrl, {
    accountId: accountData.id || accountData._id
  })
  console.log('invites', data)
  return data.invitations
}

function showOneInvite (accountData, oneInvite) {
  let inviteLink = location.protocol + '//' + location.host + '/app/invite/:inviteId'
  inviteLink = fillString(inviteLink, {
    inviteId: oneInvite.id || oneInvite._id,
    accountId: accountData.id || accountData._id
  })

  const inviteHtml = fillString(htmlInviteShowOne, {
    invitedEmail: oneInvite.email,
    invitedDate: oneInvite.createdAt,
    invitedByName: oneInvite._invitedBy?.name + ' (' + oneInvite._invitedBy?.email + ')',
    inviteRights: oneInvite.rights,
    inviteLink
  })

  return inviteHtml
}
