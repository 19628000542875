import { generateRandomString } from '../../helpers/helpers/random.js'
import { fillString } from '../../helpers/templating/string-template.js'

/**
 * Fills an HTML template with a parent element having an id attribute id=":elementId"
 * @param {String} htmlTemplate - an HTML template with a parent element having an id attribute id=":elementId"
 * @returns {String} - the HTML string with the :elementId filled
 */
export function fillElementHtmlId (htmlTemplate) {
  const elementId = generateRandomString()
  const htmlString = fillString(htmlTemplate, { elementId })

  return {
    id: elementId,
    html: htmlString
  }
}
