
import dayjs from 'dayjs'

export function analyseStepsDuration (allSteps) {
  let previousStep = null

  allSteps?.forEach(function (step, index) {
    if (previousStep) {
      previousStep.timestampEnd = step.timestamp

      const start = dayjs(previousStep.timestamp)
      const end = dayjs(previousStep.timestampEnd)
      const stepTime = end.diff(start)

      previousStep.stepTime = stepTime

      if (stepTime >= 1000) {
        previousStep.isSlow = true
      }
    }

    previousStep = step
  })
}
