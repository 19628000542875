
import htmlTemplateIndex from './index.html'
import { appConfig } from '../../../config/config.js'
import { appUser } from '../../../modules/user/user.js'
import { appAccount } from '../../../modules/user/account.js'
import { appHtmlParser } from '../../../modules/user/html-parser.js'
import { context, updateView } from './update-view.js'
import { showSelectors } from './show-selectors.js'
import { generateRandomString } from '../../../../helpers/helpers/random.js'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { activateActions } from './activate-actions.js'

/**
 *
 * @returns
 */
export async function showPageAnalysisByContact (analysisType) {
  console.log('showPageAnalysisByContact')

  if (appUser.enforceRestrictedPage({ allowPublic: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(appConfig.contentElementId)
  if (!DOMelement) return

  const accountData = appAccount.accountData()
  console.log('accountData', accountData)

  let htmlPageLayout = appHtmlParser.applyRestrictions(htmlTemplateIndex, { accountData })

  const elementId = 'i' + generateRandomString()
  const analysisTypeLabel = analysisTypes[analysisType]
  console.log(analysisType, 'analysisTypeLabel', analysisTypeLabel)
  htmlPageLayout = fillString(htmlPageLayout, {
    elementId,
    pageTitle: analysisTypeLabel
  })

  // Inject the page in the app container
  DOMelement.innerHTML = htmlPageLayout

  context.analysisType = analysisType

  document.title = [analysisTypeLabel, ': ', accountData.name].join(' ')

  document.getElementById('app-content').classList.add('position-absolute')
  document.getElementById('app-content').classList.add('top-to-bottom')

  showSelectors(updateView)

  activateActions(document.getElementById(elementId))
}

const analysisTypes = {
  topExpenses: 'Biggest Expenses',
  newExpenses: 'New Expenses',
  endedExpenses: 'Ended Expenses',
  topRevenue: 'Top Revenue Contributors',
  newRevenue: 'New Revenue Contributions',
  endedRevenue: 'Lost Revenue Contributors',
  allPnl: 'All P&L impacts'
}
