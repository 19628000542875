import { fillString } from '../../helpers/templating/string-template.js'

/**
 *
 * Calls and Returns a fetch
 * @param {String} endpointUrl
 * @param {Object} [urlParams]
 * @param {Object} [options]
 * @param {String} [options.method] eg. POST or PUT, DELETE
 * @returns
 */
export async function callApi (endpointUrl, urlParams, options) {
  const preparedUrl = fillString(endpointUrl, urlParams)
  // console.log('callApi', endpointUrl, urlParams, preparedUrl)

  const fetchOptions = prepareFetchOptions(options)

  return fetch(preparedUrl, fetchOptions)
    .then(function (response) {
      if (response.status === 401) return // Unauthorised
      return response.json()
    })
    .catch(function (error) {
      console.warn('error:', error)

      // Throw Error so it can be caught in try{} catch() {} blocks
      throw new Error(error)
      // return error
    })
    .then(function (responseData) {
      return responseData
    })
}

function prepareFetchOptions (options) {
  const fetchOptions = {}

  if (!options) return fetchOptions

  fetchOptions.method = (options.method || 'get').toUpperCase()

  if (['POST', 'PUT'].includes(fetchOptions.method)) {
    fetchOptions.headers = {
      'Content-Type': 'application/json'
    }
    fetchOptions.body = options.body || {}
    if (typeof fetchOptions.body === 'object') {
      fetchOptions.body = JSON.stringify(fetchOptions.body)
    }
  }

  return fetchOptions
}
