import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../helpers/dom.js'

export function showNoNew (context) {
  const templateForNewNew = 'No new account used for :entityName over the period from :dateFrom to :dateTo'

  const targetOrg = context.accountData._accountOrganisations.find(function (oneAccountOrg) {
    return oneAccountOrg._xeroOrganisation?._id === context.showOrgId
  })

  const stringToShow = fillString(templateForNewNew, {
    entityName: targetOrg?._xeroOrganisation?.name,
    dateFrom: context.dates.min || 'any time',
    dateTo: context.dates.max || 'any time'
  })
  setElementHtml('grid', stringToShow)
}
