
/**
 *
 * @param {*} arrayOfObject
 * @param {*} propertiesNotToInclude
 * @returns
 */
export function generateAdditionalColumnDefs (arrayOfObject, propertiesNotToInclude) {
  // console.log('generateAdditionalColumnDefs', arrayOfObject)

  const allProperties = findAllProperties(arrayOfObject)

  const additionalColumnDefs = []

  allProperties?.forEach(function (oneProperty) {
    if (propertiesNotToInclude?.includes(oneProperty)) return

    additionalColumnDefs.push({
      field: oneProperty
    })
  })
  console.log('additionalColumnDefs', additionalColumnDefs)
  return additionalColumnDefs
}

/**
 *
 * @param {*} array
 * @returns
 */
function findAllProperties (array) {
  const allProperties = new Set()

  array?.forEach(function (oneObj) {
    Object.keys(oneObj)?.forEach(function (oneProperty) {
      allProperties.add(oneProperty)
    })
  })
  console.log('allProperties', allProperties)

  return Array.from(allProperties)
}
