import { renderActive } from './render-active.js'

export default function enableOptions (selectorOptions, optionSelectorData, privateInstance) {
  console.log('enableOptions', selectorOptions, optionSelectorData, privateInstance)

  selectorOptions.modules?.optionsGroupBy?.options?.forEach(function (oneOption) {
    privateInstance.data.allowedChoices.push(oneOption)
  })

  // Take the Options to the sub module itself
  useOptionSelectorParams(selectorOptions, privateInstance)
  console.log('current value', privateInstance.api.getValue())

  // Take the module values to the parent Selector
  passModuleValuesToOptionSelector(optionSelectorData, privateInstance)

  // Set the callback functions
  privateInstance.callbacks.onChangeInternal = function () {
    passModuleValuesToOptionSelector(optionSelectorData, privateInstance)
    renderActive(optionSelectorData, privateInstance)
  }
  privateInstance.callbacks.onChange = selectorOptions.onChange
}

/**
 *
 * @param {*} optionSelectorData
 * @param {*} module
 */
function passModuleValuesToOptionSelector (optionSelectorData, privateInstance) {
  optionSelectorData.optionsGroupBy.selected = privateInstance.api.getValue()
}

/**
 * Takes the optional defaults given to the OptionSelector and bring them to the module
 * @param {*} selectorOptions
 * @param {*} module
 */
function useOptionSelectorParams (selectorOptions, privateInstance) {
  console.log('useOptionSelectorParams', selectorOptions, privateInstance)
  if (!Array.isArray(selectorOptions?.modules?.optionsGroupBy?.default)) return

  privateInstance.api.setValue(selectorOptions?.modules?.optionsGroupBy?.default)
}
