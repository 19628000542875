
// App config
import { fillString } from '../../../../helpers/templating/string-template.js'
import * as configVar from '../../../config/config.js'
import { callApi } from '../../../helpers/fetch.js'

// Dependencies
import * as mUser from '../../../modules/user/user.js'

// Page Module
import htmlInviteAccept from './invite-accept.html'

/**
 *
 * @returns
 */
export async function show (params) {
  console.log('/invite accept show()', params)

  if (mUser.enforceRestrictedPage({ allowNoAccount: true })) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  document.title = ['Invited to Zenflow'].join(' ')

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlInviteAccept

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const { inviteId } = params
  listeners(inviteId)

  showTargetInvite(inviteId)

  // TODO: Load the invite and show the UI corresponding
  // loadInvite(inviteId)
}

function listeners (inviteId) {
  console.log('listeners', inviteId)
  const acceptButton = document.getElementById('acceptInvite')
  acceptButton.addEventListener('click', function (clickEvent) {
    // Hide the button
    // acceptButton.hidden = true

    acceptButton.classList.add('is-loading')
    acceptButton.setAttribute('disabled', true)

    // const feedbackElement = document.getElementById('feedback')
    // feedbackElement.innerText = 'Loading'

    acceptInvite(inviteId)
  })
}

async function showTargetInvite (inviteId) {
  const acceptanceElement = document.getElementById('forAcceptance')
  acceptanceElement.hidden = true

  const invalidElement = document.getElementById('invalidInvite')
  invalidElement.hidden = true

  const apiData = await loadInvite(inviteId)
  console.log('invite', apiData)

  if (apiData.invite) {
    acceptanceElement.hidden = false

    const accountNameElement = document.getElementById('accountName')
    accountNameElement.innerText = apiData.invite._account?.name
  } else {
    invalidElement.hidden = false
  }
}

/**
 *
 * @param {*} inviteId
 */
async function loadInvite (inviteId) {
  const apiUrl = '/api/invite/one/:inviteId'

  const data = await callApi(apiUrl, {
    inviteId
  })

  return data
}

/**
 *
 * @param {*} inviteId
 */
async function acceptInvite (inviteId) {
  console.log('acceptInvite', arguments)
  const apiUrl = '/api/invite/accept/:inviteId'

  const data = await callApi(apiUrl, {
    inviteId
  }, {
    method: 'PUT'
  })

  if (!data) {
    console.error('Invitation could not be accepted')

    const feedbackElement = document.getElementById('feedback')
    feedbackElement.innerText = 'The invitation could not be accepted.'

    return
  }

  const redirectUrl = fillString('/app/a/:accountId/entities', { accountId: data._account._id })

  location.href = redirectUrl
}
