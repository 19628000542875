import htmlTemplate from './grid-container.html'
import './style.less'

import { createGrid } from 'ag-grid-community'
import { setElementHtml } from '../../../../helpers/dom.js'
import { makeColumnDefs } from './make-column-defs.js'
import { analyseStepsDuration } from './analyse-steps-duration.js'

const CONTAINER_ID = 'syncSteps'
const GRID_CONTAINER_ID = 'gridForSteps'
const dataProperty = 'allSteps'

export function renderGridForSteps (syncData) {
  console.log('renderGridForSteps', syncData)
  const dataset = syncData[dataProperty]
  if (!dataset) {
    setElementHtml(CONTAINER_ID, 'no data yet')
    return
  }

  const containerElement = setElementHtml(CONTAINER_ID, htmlTemplate)
  if (!containerElement) return

  const columnDefs = makeColumnDefs(dataset)
  console.log(columnDefs)

  analyseStepsDuration(dataset)

  const gridOptions = {
    rowData: dataset,
    columnDefs,
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    }
  }

  const gridContainerElement = document.getElementById(GRID_CONTAINER_ID)
  createGrid(gridContainerElement, gridOptions)
}
