// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mIntercom from '../intercom/intercom.js'
import * as mReferrals from '../../../helpers/referrals/referrals.js'

// Module
import htmlLoggedOut from './logged-out.html'
import htmlLoggedIn from './logged-in.html'
import * as mAccount from './account.js'
import { callApi } from '../../helpers/fetch.js'
import { fillString } from '../../../helpers/templating/string-template.js'

// Use storage in the module
const moduleStore = {}
moduleStore.userData = null

// Define the DOM element IDs
const userHeaderElementId = configVar.layoutAuthElementId || 'signin'

export function userData () {
  return moduleStore.userData
}

export function isUserLoggedIn () {
  // console.log('isUserLoggedIn?', moduleStore.userData)
  if (moduleStore.userData) {
    return true
  } else {
    return false
  }
}

export function loginUser (apiResponse) {
  // console.log('loginUser', apiResponse)
  // Record when we received an API response
  if (apiResponse?.email) {
    // console.log('set user data')
    moduleStore.userData = apiResponse
    mIntercom.identifyUser(moduleStore.userData)
    mReferrals.recordUserReferral()
  }
  showUserHeader()
}

export function logoutUser () {
  moduleStore.userData = false
  showUserHeader()
}

export function showUserHeader () {
  // console.log('showUserHeader')
  if (isUserLoggedIn()) {
    showLoggedInUser()
  } else {
    showLoggedOutUser()
  }
}

export async function loadUser () {
  // console.log('loadUser')
  const urlApi = [configVar.authRoutes, 'profile'].join('/')

  const response = await callApi(urlApi)

  // console.log('response', response)
  loginUser(response)
  return 'loadUser done'
}

function showLoggedOutUser () {
  // console.log('showLogged OUT User')
  const DOMelement = document.getElementById(userHeaderElementId)
  if (!DOMelement) return

  DOMelement.innerHTML = htmlLoggedOut
}

function showLoggedInUser () {
  // console.log('showLogged IN User')
  const DOMelement = document.getElementById(userHeaderElementId)
  if (!DOMelement) return

  DOMelement.innerHTML = generateUserHeader()
}

function generateUserHeader () {
  const menu = fillString(htmlLoggedIn, { username: userData().name })

  return menu
}

export function showRestrictedPage () {
  mAccount.showUnauthorisedAccount({ isUserLoggedIn: isUserLoggedIn() })
}

/**
 * Ensure (1) the account is public or (2) the user (2.1) is logged in and (2.2) has access to the account
 * @param {Object} [options]
 * @param {Boolean} [options.allowPublic] true to allow public accounts
 * @param {Boolean} [options.allowNoAccount] true to allow page for logged in user, without a specific account?
 * @returns true if restriction not successful
 */
export function enforceRestrictedPage (options) {
  const _options = {
    allowPublic: options?.allowPublic || false,
    allowNoAccount: options?.allowNoAccount || false
  }
  let restrict = true

  const accountData = mAccount.accountData()
  // console.log('enforceRestrictedPage', _options, 'accountData', accountData)

  if (!isUserLoggedIn()) {
    // console.log('enforceRestrictedPage: no user logged in')
    // When no logged in, user should only access isPublic accounts
    if (_options.allowPublic === true && accountData?.isPublic) {
      restrict = false
    } else {
      return returnRestriction()
    }
  } else {
    restrict = false
  }

  // User is logged in: good to go, except if the account itself is restricted: ie no accountData
  // accountData is null if the account is restricted
  if (!accountData) {
    // console.log('enforceRestrictedPage: no account data')
    if (_options.allowNoAccount) {
      restrict = false
    } else {
      return returnRestriction()
    }
  } else {
    restrict = false
  }

  return restrict
}

function returnRestriction () {
  showRestrictedPage()
  return true
}

export const appUser = {
  userData,
  isUserLoggedIn,
  loginUser,
  logoutUser,
  showUserHeader,
  loadUser,
  showRestrictedPage,
  enforceRestrictedPage
}
