import onChange from './on-change.js'

export default function addListeners (privateInstance) {
  const htmlElement = privateInstance.data?.htmlElement

  htmlElement.addEventListener('click', async function (clickEvent) {
    const buttonClicked = clickEvent.target.closest('.button')
    if (!buttonClicked) return

    const currencySymbol = buttonClicked.getAttribute('d-currencySymbol')
    await onChange(privateInstance, currencySymbol)
  })
}
