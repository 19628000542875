import htmlIntro from './sync-details.html'

import dayjs from 'dayjs'

import { fillString } from '../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../helpers/dom.js'
import { timeDuration } from '../../../../helpers/helpers/time.js'
import { renderGridForModules } from './show-modules/render-grid.js'
import { renderGridForErrors } from './show-errors/render-grid.js'
import { renderGridForSteps } from './show-steps/render-grid.js'

/**
 *
 * @param {Object} syncData
 */
export function renderSync (syncData) {
  console.log('renderSync', syncData)

  const moduleElement = renderSyncIntro(syncData)
  console.log(moduleElement)

  renderGridForModules(syncData)
  renderGridForErrors(syncData)
  renderGridForSteps(syncData)
}

/**
 *
 * @param {*} syncData
 * @returns
 */
function renderSyncIntro (syncData) {
  const readyHtml = fillString(htmlIntro, {
    orgName: syncData._xeroOrganisation?.details?.name,
    orgId: syncData._xeroOrganisation?._id,
    status: syncData.status,
    totalApiCalls: syncData.totalApiCalls,
    numberSteps: syncData.allSteps?.length,
    scheduledTime: syncData.scheduledTime ? dayjs(syncData.scheduledTime).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    startedAt: syncData.startedAt ? dayjs(syncData.startedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    endedAt: syncData.endedAt ? dayjs(syncData.endedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    totalTime: timeDuration(syncData.startedAt, syncData.endedAt)?.timeText,
    origin: syncData.origin,
    createdAt: syncData.createdAt ? dayjs(syncData.createdAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    updatedAt: syncData.updatedAt ? dayjs(syncData.updatedAt).format('YYYY-MM-DD @ HH:mm:ss') : '-',
    numberTokens: syncData.infos?.numberOfTokens || '??',
    hasError: syncData.syncErrors?.length || 'n/a'
  })

  const moduleElement = setElementHtml('syncDetails', readyHtml)
  return moduleElement
}
