
// Page Module
import { callApi } from '../../../helpers/fetch.js'
import htmlInviteCreate from './invite-create.html'
import * as mInviteList from './invite-list.js'
import * as mUserList from '../users/user-list.js'

export function showInviteCreation (accountData) {
  const containerElement = document.getElementById('createInvite')
  containerElement.innerHTML = htmlInviteCreate

  const formElement = containerElement.querySelector('form')

  formElement.addEventListener('submit', function (submitEvent) {
    console.log('form submitEvent', submitEvent)
    manageInviteSubmit(accountData)

    submitEvent.preventDefault()
  })
}

/**
 * Should work on Enter, and button click
 */
function manageInviteSubmit (accountData) {
  const invitedEmailElement = document.getElementById('invitedEmail')
  const invitedEmail = invitedEmailElement.value
  console.log('invitedEmail', invitedEmail)

  createInvite(accountData, invitedEmail)
}

/**
 *
 * @param {*} accountData
 */
async function createInvite (accountData, invitedEmail) {
  const data = {
    email: invitedEmail,
    accountId: accountData.id || accountData._id,
    rights: ['canView']
  }
  const apiUrl = '/api/invite/create'
  await callApi(apiUrl, null, {
    method: 'POST',
    body: JSON.stringify(data)
  })

  // Refresh the user listing
  mUserList.showUserList(accountData)
  mInviteList.showInviteList(accountData)
}
