import htmlTemplate from './grid-container.html'
import './style.less'

import { createGrid } from 'ag-grid-community'
import { setElementHtml } from '../../../../helpers/dom.js'
import { makeColumnDefs } from './make-column-defs.js'

const CONTAINER_ID = 'syncedModules'
const GRID_CONTAINER_ID = 'gridForModules'
const dataProperty = 'modules'

export function renderGridForModules (syncData) {
  console.log('renderGridForModules', syncData)
  const dataset = syncData[dataProperty]
  if (!dataset) {
    setElementHtml(CONTAINER_ID, 'no data yet')
    return
  }

  const containerElement = setElementHtml(CONTAINER_ID, htmlTemplate)
  if (!containerElement) return

  const columnDefs = makeColumnDefs(dataset)

  const gridOptions = {
    rowData: dataset,
    columnDefs,
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    }
  }

  const gridContainerElement = document.getElementById(GRID_CONTAINER_ID)
  createGrid(gridContainerElement, gridOptions)
}
