
export default async function onChange (privateInstance, newValue) {
  // console.log('onChange', newValue)

  privateInstance.api.toggleValue(newValue)
  // console.log('getValue()', privateInstance.api.getValue())

  // Execute callbacks
  if (privateInstance.callbacks?.onChangeInternal) {
    console.log('>>> groupBy >> executing onChangeInternal')
    await privateInstance.callbacks.onChangeInternal()
    console.log('<<< groupBy << executing onChangeInternal')
  }

  if (privateInstance.callbacks?.onChange) {
    console.log('>>> groupBy >> executing onChange')
    await privateInstance.callbacks.onChange(privateInstance.api)
    console.log('<<< groupBy << executing onChange')
  }
}
