
export function renderActive (optionSelectorData, privateInstance) {
  const currenciesElement = document.getElementById('optionSelectorContent')
  if (!currenciesElement) return

  currenciesElement.querySelectorAll('.button').forEach(function (button) {
    const currencySymbol = button.getAttribute('d-currencySymbol')

    if (privateInstance.api.getValue() === currencySymbol) {
      button.classList.add('is-active')
      button.querySelector('.icon').classList.remove('is-hidden')
    } else {
      button.classList.remove('is-active')
      button.querySelector('.icon').classList.add('is-hidden')
    }
  })
}
