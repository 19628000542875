import { callApi } from '../../client/helpers/fetch.js'
import { apiEndpoint } from './settings.js'

export function readReferrer () {
  // console.log('document.referrer:', document.referrer)
  if (!document.referrer) return
  const referrerURL = new URL(document.referrer)
  // console.log(referrerURL.href)
  // console.log(referrerURL.host)

  if (referrerURL.host !== location.host) {
    // console.log('from external host')
    updateStoredReferrer(referrerURL.href)
  }
}

function updateStoredReferrer (referrerUrl) {
  const previousReferrers = localStorage.getItem('referrers')
  const referrers = []
  if (previousReferrers) {
    referrers.push(...JSON.parse(previousReferrers))
  }
  referrers.push(referrerUrl)
  localStorage.setItem('referrers', JSON.stringify(referrers))
}

/**
 * Send the request to record to server
 * Then remove the client stored referrals as they have been recorded
 */
export async function recordUserReferral () {
  // Send a fetch POST request to record
  const referrers = localStorage.getItem('referrers')
  // console.log('recordUserReferral', referrers)

  // No need to record if no referrer
  if (!referrers) return

  await callApi(apiEndpoint,
    {
      body: {
        referrers
      }
    }, {
      method: 'POST'
    }
  )
  localStorage.removeItem('referrers')
  return true
}
