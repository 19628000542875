import onChange from './on-change.js'

export default function addListeners (privateInstance) {
  if (privateInstance.data.listenersAdded) return

  const htmlElement = privateInstance.data?.htmlElement

  htmlElement.addEventListener('click', async function (clickEvent) {
    const buttonClicked = clickEvent.target.closest('.button')
    if (!buttonClicked) return

    const optionValue = buttonClicked.getAttribute('d-option')
    await onChange(privateInstance, optionValue)
  })

  privateInstance.data.listenersAdded = true
}
