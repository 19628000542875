import { disableUser } from './disable-user.js'

export function addListeners (containerElement) {
  containerElement.addEventListener('click', async function (clickEvent) {
    console.log('click', clickEvent)
    const disableButton = clickEvent.target.closest('[data-action="disable"]')
    if (!disableButton) return

    const userId = disableButton.getAttribute('data-user-id')
    const inviteId = disableButton.getAttribute('data-invite-id')
    console.log('userId', userId)
    console.log('inviteId', inviteId)

    await disableUser(inviteId)

    // Reload the page after disabling the user
    window.location.reload()
  })
}
