import dayjs from 'dayjs'
import { callApi } from '../../../helpers/fetch.js'
import { setElementHtml, showElement } from '../../../helpers/dom.js'

/**
 *
 * @param {Object} context
 * @param {String} context.showOrgId
 * @param {Object} [context.dates]
 * @param {String} [context.dates.min]
 * @param {String} [context.dates.max]
 * @returns
 */
export async function loadDataTopExpenses (context) {
  console.log('loadDataTopExpenses')
  console.log('context.showOrgId', context.showOrgId)

  if (!context.showOrgId) return

  // The orgIds to fetch data for
  const orgIds = []
  if (!Array.isArray(context.showOrgId)) {
    orgIds.push(context.showOrgId)
  } else {
    orgIds.push(...context.showOrgId)
    document.getElementById('progressBar')?.setAttribute('value', 0)
  }

  // Load the data for each entity
  const promises = []
  for (const orgId of orgIds) {
    const orgData = getOrgData(context.accountData?._accountOrganisations, orgId)

    const promise = loadOneEntity(orgId, context.dates, orgData, {
      numberProgressStep: orgIds.length
    })
    promises.push(promise)
  }

  const results = {
    withContact: []
  }

  try {
    const loadedData = await Promise.all(promises)

    // Concatene the values together
    loadedData.forEach(function (oneOrgData) {
      results.withContact = results.withContact.concat(oneOrgData.withContact)
    })
  } catch (error) {
    console.warn('error', error)
    results.hasError = true
  }

  // Return
  console.log('results', results)
  return results
}

/**
 *
 * @param {*} orgId
 * @param {*} dates
 * @returns
 */
async function loadOneEntity (orgId, dates, orgData, otherParams) {
  console.log('loadOneEntity', dates)
  const monthFrom = dates.min || dayjs().add(-1, 'month').format('YYYY-MM')
  const monthTo = dates.max || dayjs().format('YYYY-MM')
  console.log('monthFrom', monthFrom, 'monthTo', monthTo)

  try {
    const loadedData = await callApi('/api/finlogic/data/:orgId/by-contact/:monthFrom/:monthTo', {
      orgId,
      monthFrom,
      monthTo
    })

    // Add the org info to each value
    loadedData?.withContact?.forEach(function (oneValue) {
      oneValue.entity = orgData
      oneValue.currencySymbol = orgData.details?.baseCurrency
    })

    if (otherParams.numberProgressStep > 1) {
      showElement('progressBar')
      const progressBar = document.getElementById('progressBar')
      const currentValue = progressBar?.getAttribute('value')

      const oneStepSize = 100 / otherParams.numberProgressStep
      const newValue = +currentValue + oneStepSize
      document.getElementById('progressBar')?.setAttribute('value', newValue)
    }

    return loadedData
  } catch (error) {
    console.warn('error', error)

    setElementHtml('feedbackMessage', 'Oops, an error occured. Refresh the page and try again')
    showElement('feedbackMessage')
    throw new Error(error)
  }
}

function getOrgData (accountDataAccountOrg, orgId) {
  const org = accountDataAccountOrg?.find(function (one) {
    return one._xeroOrganisation._id === orgId
  })

  return org?._xeroOrganisation
}
