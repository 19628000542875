import './switch.less'

import { createGrid } from 'ag-grid-community'
import { makeRows } from './make-rows.js'

export function showGrid (accountData, currencyList) {
  const gridOptions = {
    rowData: makeRows(accountData, currencyList),
    columnDefs: makeColumnDefs(accountData),
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    getRowId: function (cellParams) {
      return cellParams.data.symbol
    }
  }

  console.log('gridOptions', gridOptions)

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  console.log('gridContainerElement', gridContainerElement)

  const gridApi = createGrid(gridContainerElement, gridOptions)
  return gridApi
}

function makeColumnDefs (accountData) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Code',
    valueGetter: function (cellParams) {
      return cellParams.data.symbol
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Use?',
    valueGetter: function (cellParams) {
      const isActive = cellParams.data.active
      return isActive
    },
    cellRenderer: function (cellParams) {
      return `<div 
        class="switch" 
        data-symbol="${cellParams.data.symbol}" 
        data-active="${cellParams.value}">
      <input type="checkbox" ${cellParams.value ? 'checked' : ''}>
      <span class="slider"></span>
      </div>`
    },
    pinned: 'left',
    width: 100
  })

  columnDefs.push({
    headerName: 'Currency',
    valueGetter: function (cellParams) {
      return cellParams.data.name
    }
  })

  return columnDefs
}
