import { renderActive } from './render-active.js'

export default function enableOptions (selectorOptions, optionSelectorData, privateInstance) {
  // console.log('enableOptions', selectorOptions, optionSelectorData, privateInstance)

  privateInstance.data.allowedChoices.push('XXX')

  selectorOptions.modules?.optionCurrencies?.currencies?.forEach(function (oneCurrency) {
    privateInstance.data.allowedChoices.push(oneCurrency.symbol)
  })

  // Take the Options to the sub module itself
  useOptionSelectorParams(selectorOptions, privateInstance)

  // Take the module values to the parent Selector
  passModuleValuesToOptionSelector(optionSelectorData, privateInstance)

  // Set the callback functions
  privateInstance.callbacks.onChangeInternal = function () {
    passModuleValuesToOptionSelector(optionSelectorData, privateInstance)
    renderActive(optionSelectorData, privateInstance)
  }
  privateInstance.callbacks.onChange = selectorOptions.onChange
}

/**
 *
 * @param {*} optionSelectorData
 * @param {*} module
 */
function passModuleValuesToOptionSelector (optionSelectorData, privateInstance) {
  optionSelectorData.optionsCurrencies.symbol = privateInstance.api.getValue()
}

/**
 * Takes the optional defaults given to the OptionSelector and bring them to the module
 * @param {*} selectorOptions
 * @param {*} module
 */
function useOptionSelectorParams (selectorOptions, privateInstance) {
  privateInstance.api.setValue(selectorOptions?.modules?.optionCurrencies?.default)
}
