
// App config
import * as configVar from '../../config/config.js'

// Dependencies
import * as mUser from '../../modules/user/user.js'
import * as mAccount from '../../modules/user/account.js'

import { apiEndpointCurrencyList } from '../../../helpers/fx/settings.js'

// Page Module
import htmlIndex from './index.html'
import { callApi } from '../../helpers/fetch.js'
import { showGrid } from './show-grid.js'
import { hideElement } from '../../helpers/dom.js'
import { toBoolean } from '../../../helpers/helpers/string.js'
import { associate } from './associate.js'
import { isCurrencyActive } from './is-currency-active.js'

/**
 *
 * @returns
 */
export async function show () {
  console.log('module show currencies')

  if (mUser.enforceRestrictedPage()) return

  // Ensure the page content element is available
  const DOMelement = document.getElementById(configVar.contentElementId)
  if (!DOMelement) return

  // Create the page DOM Element:

  // Use an intermediary Element to manipulate it before adding to the actual DOM
  const contentElement = document.createElement('div')
  contentElement.innerHTML = htmlIndex

  // Inject the page in the app container
  DOMelement.innerHTML = ''
  DOMelement.append(contentElement)

  const accountData = mAccount.accountData()
  document.title = ['Currencies:', accountData.name].join(' ')

  await showCurrencies()

  hideElement('loadingFeedback')
}

function listeners (accountData, gridApi) {
  document.querySelector('#currencyList').addEventListener('click', async function (clickEvent) {
    const switchElement = clickEvent.target.closest('.switch')
    if (!switchElement) return

    const isLoading = switchElement.classList.contains('isLoading')
    if (isLoading) return

    const symbol = switchElement.getAttribute('data-symbol')
    const isActive = switchElement.getAttribute('data-active')
    // console.log(symbol, 'isActive', isActive)

    const newActive = !(toBoolean(isActive))
    // console.log('newActive', newActive)

    const inputElement = switchElement.querySelector('input')
    // console.log('inputElement', inputElement)
    inputElement.checked = newActive

    switchElement.classList.add('isLoading')
    await associate({ symbol, change: newActive ? 'on' : 'off' }, accountData)
    switchElement.classList.remove('isLoading')

    const gridNode = gridApi.getRowNode(symbol)

    gridNode.data.active = isCurrencyActive(accountData, symbol)
    gridNode.updateData(gridNode.data)
  })
}

async function showCurrencies () {
  const accountData = mAccount.accountData()

  if (!accountData) return
  if (!accountData._id) return

  const currencyList = await loadCurrencyList()
  console.log('currencyList', currencyList)

  const currencyListElement = document.getElementById('currencyList')
  if (!currencyListElement) return

  const gridApi = showGrid(accountData, currencyList)
  listeners(accountData, gridApi)
}

/**
 *
 * @returns
 */
async function loadCurrencyList () {
  console.log('loadCurrencyList')

  const apiUrl = apiEndpointCurrencyList
  const data = await callApi(apiUrl)
  return data
}
