import htmlTemplate from './grid-container.html'
import './style.less'

import { createGrid } from 'ag-grid-community'
import { setElementHtml } from '../../../../helpers/dom.js'
import { makeColumnDefs } from './make-column-defs.js'

const CONTAINER_ID = 'syncErrors'
const GRID_CONTAINER_ID = 'gridForErrors'
const dataProperty = 'syncErrors'

export function renderGridForErrors (syncData) {
  console.log('renderGridForErrors', syncData)

  if (!syncData.syncErrors || syncData.syncErrors.length === 0) {
    setElementHtml(CONTAINER_ID, 'No error')
    return
  }

  const containerElement = setElementHtml(CONTAINER_ID, htmlTemplate)
  if (!containerElement) return

  const dataset = syncData[dataProperty]

  const columnDefs = makeColumnDefs(dataset)
  console.log('columnDefs', columnDefs)
  console.log('dataset', dataset)

  const gridOptions = {
    rowData: dataset,
    columnDefs,
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    }
  }

  const gridContainerElement = document.getElementById(GRID_CONTAINER_ID)
  createGrid(gridContainerElement, gridOptions)
  // console.log(gridApi)
}
