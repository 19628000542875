import { ascending, descending } from 'd3'
import { isCurrencyActive } from './is-currency-active.js'

export function makeRows (accountData, currencyList) {
  const currencies = currencyList.list.map(function (currency) {
    return {
      symbol: currency.symbol,
      name: currency.name,
      active: isCurrencyActive(accountData, currency.symbol)
    }
  })

  currencies.sort(function (a, b) {
    return descending(a.active, b.active) || ascending(a.symbol, b.symbol)
  })

  return currencies
}
