import { fillString } from '../../helpers/templating/string-template.js'

/**
 * Generator of links to Xero pages
 *
 */

const xeroDeepLinks = {
  Home: 'https://go.xero.com/app/:shortCode/dashboard',
  Banks: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/BankAccounts.aspx',
  BankStatement: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/Statements.aspx?accountID=:bankAccountId',
  XeroToXero: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Settings/Xero2Xero',
  FinancialSettings: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Setup/FinancialSettings.aspx',
  ChartAccounts: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/GeneralLedger/ChartOfAccounts.aspx',
  Trackings: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Setup/Tracking.aspx?:categorySpecific', // categoryID=:categoryId

  // Contacts
  // Contact: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Contacts/View/:xeroContactId', // Until Mid 2024
  Contact: 'https://go.xero.com/app/:shortCode/contacts/contact/:xeroContactId', // New Xero template 2024

  //
  ActivitySummary: 'https://go.xero.com/app/:shortCode/activity-summary',

  // Account Transactions: unfortunately no way to pass the account selected??
  // US: 1109
  // GB: 1209
  // Others: 1009
  //  https://developer.xero.com/documentation/guides/how-to-guides/deep-link-xero/
  // ReportAccountTransactions: 'https://reporting.xero.com/:shortCode/v1/Run/1009',
  ReportAccountTransactions: 'https://reporting.xero.com/:shortCode/v1/Run?reportId=1eea47a0-1844-461b-b188-950eabb97527&from=:fromISOdate&to=:toISOdate&accounts[]=:xeroAccountId&accrualBasis=true&showAccountingBasis=false&accountsToInclude=withTransactionsOrNonZeroBalance',
  // Seen Sep 2024: https://reporting.xero.com/:shortCode/v1/Run?reportId=1eea47a0-1844-461b-b188-950eabb97527&from=2019-01-01&to=2019-01-30&accounts[]=:xeroAccountId&accrualBasis=true&showAccountingBasis=false&accountsToInclude=withTransactionsOrNonZeroBalance
  // https://reporting.xero.com/!1Dx5B/v1/Run?reportId=1eea47a0-1844-461b-b188-950eabb97527&from=2024-09-01&to=2024-09-30&accounts[]=0877248d-07bd-4b12-b3b3-9f847b85af48&accrualBasis=true&showAccountingBasis=false&accountsToInclude=withTransactionsOrNonZeroBalance
  // *********

  // Sources
  AnySource: 'https://go.xero.com/GeneralLedger/ShowSubsidiary.aspx?invoiceID=:sourceId', // Seen Sep 22nd, 2024 on the General Ledger Details report

  BankTransaction: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',
  BankTransfer: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:bankTransactionId',
  // Previous bug in storing of BankTransaction as BankTransation
  BankTransation: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',

  // Invoices:
  Invoice: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=:sourceId',

  Bill: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=:sourceId',

  //
  //
  CreditNotePayable: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsPayable/ViewCreditNote.aspx?creditNoteID=:sourceId',
  CreditNoteReceivable: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=:sourceId',

  // Payments
  Payment: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=:sourceId',
  ManualJournal: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=:shortCode&redirecturl=/Journal/View.aspx?invoiceID=:sourceId'
}

export function sourceTypeMapped (type) {
  const linkType = xeroSourceTypes[type] || type

  return linkType
}

/**
 * https://developer.xero.com/documentation/api/accounting/types/#journals
 * The typeApi for the xeroSourceType
 *
 * !! the type is difference betwenn the Journals and the Source itself
 */
const xeroSourceTypes = {
  ACCREC: 'Invoice',
  ACCPAY: 'Bill', //  Accounts Payable Invoice
  ACCRECCREDIT: 'CreditNoteReceivable', //  Accounts Receivable Credit Note
  ACCPAYCREDIT: 'CreditNotePayable', //  Accounts Payable Credit Note
  ACCRECPAYMENT: 'Payment', //  Payment on an Accounts Receivable Invoice
  ACCPAYPAYMENT: 'Payment', //  Payment on an Accounts Payable Invoice
  ARCREDITPAYMENT: 'Payment', //  Accounts Receivable Credit Note Payment
  APCREDITPAYMENT: 'Payment', //  Accounts Payable Credit Note Payment
  CASHREC: 'BankTransaction', // Receive Money Bank Transaction
  CASHPAID: 'BankTransaction', // Spend Money Bank Transaction
  RECEIVE: 'BankTransaction', // Spend Money Bank Transaction
  SPEND: 'BankTransaction', // Spend Money Bank Transaction
  TRANSFER: 'BankTransfer', // Bank Transfer
  // 'SPEND-TRANSFER': 'BankTransaction', // Bank Transfer
  // 'RECEIVE-TRANSFER': 'BankTransaction', // Bank Transfer
  ARPREPAYMENT: 'CreditNoteReceivable', // Accounts Receivable Prepayment
  APPREPAYMENT: 'CreditNotePayable', // Accounts Payable Prepayment
  AROVERPAYMENT: 'CreditNoteReceivable', // Accounts Receivable Overpayment
  APOVERPAYMENT: 'CreditNotePayable', // Accounts Payable Overpayment
  'SPEND-PREPAYMENT': 'CreditNotePayable'
// EXPCLAIM Expense Claim
// EXPPAYMENT: 'Payment', //  Expense Claim Payment
// MANJOURNAL Manual Journal
// PAYSLIP Payslip
// WAGEPAYABLE Payroll Payable
// INTEGRATEDPAYROLLPE Payroll Expense
// INTEGRATEDPAYROLLPT Payroll Payment
// EXTERNALSPENDMONEY Payroll Employee Payment
// INTEGRATEDPAYROLLPTPAYMENT Payroll Tax Payment
// INTEGRATEDPAYROLLCN
}

/**
 *
 * @param {String} type
 * @param {Object} params
 * @param {String} params.shortCode
 * @param {String} [params.sourceId]
 * @param {String} [params.xeroContactId]
 * @param {String} [params.trackingCategoryId]
 * @param {String} [params.sourceType]
 * @returns
 */
export function makeXeroLink (type, params) {
  const typeMapped = sourceTypeMapped(type)
  const sourceXeroUrl = xeroDeepLinks[typeMapped]
  // console.log('makeXeroLink', type, typeMapped, sourceXeroUrl)
  if (!sourceXeroUrl) return ''

  return updateParameters(sourceXeroUrl, params)
}

function updateParameters (baseUrl, params) {
  let url = baseUrl

  const stringValues = Object.assign({}, params)
  // {
  //   shortCode: params?.shortCode || params?.shortcode, // Always needed
  //   xeroContactId: params?.xeroContactId, // Specific
  //   sourceId: params?.sourceId // Specific
  // }

  // For Trackings
  if (params?.trackingCategoryId) {
    stringValues.categorySpecific = 'categoryID=' + params.trackingCategoryId
  } else {
    stringValues.categorySpecific = ''
  }

  // For Bank Transfers
  if (params?.sourceType === 'TRANSFER') {
    stringValues.bankTransactionId = params.bankTransactionId
  }

  url = fillString(url, stringValues)

  // console.log('url', url)
  return url
}
