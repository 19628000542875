import { generateAdditionalColumnDefs } from '../helpers/include-additional-columns.js'
import { timestampFormatter } from '../helpers/timestamp-formatter.js'

export function makeColumnDefs (modulesData) {
  console.log('makeColumnDefs', modulesData)

  const columnDefs = []

  columnDefs.push({
    field: 'error',
    pinned: 'left'
  })
  columnDefs.push({
    field: 'timestamp',
    valueFormatter: timestampFormatter,
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'moduleExecutorName',
    valueGetter: function (cellParams) {
      console.log(cellParams)
      return cellParams?.data?.otherData?.moduleExecutorName || null
    }
  })

  columnDefs.push({
    headerName: 'errorProperties',
    valueGetter: function (cellParams) {
      return JSON.stringify(cellParams?.data?.errorProperties) || null
    }
  })
  columnDefs.push({
    headerName: 'otherData',
    valueGetter: function (cellParams) {
      return JSON.stringify(cellParams?.data?.otherData) || null
    }
  })

  // Other properties
  const baseProperties = [
    'error',
    'timestamp',
    'errorProperties',
    'otherData'
  ]

  const additionalColumnDefs = generateAdditionalColumnDefs(modulesData, baseProperties)
  columnDefs.push(...additionalColumnDefs)

  return columnDefs
}
