
export default async function onChange (privateInstance, newCurrencySymbol) {
  // console.log('onChange', newCurrencySymbol)

  privateInstance.api.setValue(newCurrencySymbol)
  // console.log('getValue()', privateInstance.api.getValue())

  // Execute callbacks
  if (privateInstance.callbacks?.onChangeInternal) {
    console.log('>>> currencies >> executing onChangeInternal')
    await privateInstance.callbacks.onChangeInternal()
    console.log('<<< currencies << executing onChangeInternal')
  }

  if (privateInstance.callbacks?.onChange) {
    console.log('>>> currencies >> executing onChange')
    await privateInstance.callbacks.onChange(privateInstance.api)
    console.log('<<< currencies << executing onChange')
  }
}
