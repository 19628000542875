// import { createGrid } from 'ag-grid-community'
import { hideElement, setElementHtml, showElement, showLoading } from '../../../helpers/dom.js'
import { optionSelectorData } from '../../../modules/option-selector/data.js'
import { loadDataNewAccountsUsed } from './load-data.js'
import { appAccount } from '../../../modules/user/account.js'
import { showNoNew } from './no-new.js'
import { createGrid } from 'ag-grid-community'
import { makeColumnDefs } from './make-column-defs.js'

const context = {
  data: {}
}

export let gridApi

/**
 *
 */
export async function updateView () {
  console.log('updateView')
  console.log('optionSelectorData', optionSelectorData)

  hideElement('actions')
  showLoading('grid')

  // Params in use
  context.showOrgId = optionSelectorData.organisationId || context.showOrgId
  context.dates = optionSelectorData.dates || {}
  context.optionsJournals = optionSelectorData.optionsJournals
  context.accountData = appAccount.accountData()

  // Load data
  const apiResponse = await loadDataNewAccountsUsed(context)
  console.log('context', context)

  if (!apiResponse.newAccountsUsed || apiResponse.newAccountsUsed.length === 0) {
    showNoNew(context)
    return
  }

  setElementHtml('grid', '') // Reset

  context.data = apiResponse

  const gridOptions = {
    rowData: apiResponse.newAccountsUsed,
    columnDefs: makeColumnDefs(),
    defaultColDef: {
      filter: true,
      floatingFilter: true
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
    context
  }

  const containerId = 'grid'
  const gridContainerElement = document.getElementById(containerId)
  gridApi = createGrid(gridContainerElement, gridOptions)

  console.log('gridApi', gridApi)

  showElement('actions')
}
