
export function renderActive (optionSelectorData, privateInstance) {
  console.log('renderActive', optionSelectorData, privateInstance, privateInstance.api.getValue())

  // const selectorElement = document.getElementById('optionSelectorContent')
  const selectorElement = privateInstance.data?.htmlElement
  // console.log('selectorElement', selectorElement)
  if (!selectorElement) return

  selectorElement.querySelectorAll('.button').forEach(function (button) {
    const optionValue = button.getAttribute('d-option')
    // console.log('optionValue', optionValue, 'in', privateInstance.api.getValue(), privateInstance.api.getValue().includes(optionValue))

    if (privateInstance.api.getValue().includes(optionValue)) {
      // console.log('adding active', button)
      button.classList.add('is-active')
      button.querySelector('.icon').classList.remove('is-hidden')
      // console.log('Now', button)
    } else {
      // console.log('removing active', button)
      button.classList.remove('is-active')
      button.querySelector('.icon').classList.add('is-hidden')
    }
  })
}
