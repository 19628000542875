import { callApi } from '../../helpers/fetch.js'

export async function loadAccountCurrencies (accountId) {
  const apiUrl = '/api/account/:accountId/fx-rates'
  const data = await callApi(apiUrl, {
    accountId
  })
  //   console.log('loadAccountCurrencies', data)
  return data
}
