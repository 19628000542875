import { fillString } from '../../../../helpers/templating/string-template.js'
import { callApi } from '../../../helpers/fetch.js'

/**
 * filterDates
 *  journalDates=[from,to]
 *
 * @param {*} context
 * @param {String} context.showOrgId
 * @returns
 */
export async function loadDataNewAccountsUsed (context) {
  console.log('loadDataNewAccountsUsed', context)
  const orgId = context.showOrgId
  const accountId = context.accountData._id

  if (!orgId) return
  if (!accountId) return

  let options = 'dates=:dateFrom,:dateTo'

  options = fillString(options, {
    dateFrom: context.dates.min || 'notSet',
    dateTo: context.dates.max || 'notSet'
  })

  const endpoint = '/api/xero/a/:accountId/new-used-accounts/o/:orgId?:options '
  const apiUrl = fillString(endpoint, { accountId, orgId, options })
  const loadedData = await callApi(apiUrl)
  return loadedData
}
