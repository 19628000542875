import dayjs from 'dayjs'

export function makeColumnDefs () {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      return cellParams.data?.chartOfAccounts?.name
    }
  })

  columnDefs.push({
    headerName: 'First Journal',
    valueGetter: function (cellParams) {
      return dayjs(cellParams.data?.firstJournalDate).format('DD MMM YYYY')
    }
  })

  columnDefs.push({
    headerName: 'First Usage',
    valueGetter: function (cellParams) {
      return dayjs(cellParams.data?.firstRecordDate).format('DD MMM YYYY @ HH:mm')
    }
  })

  return columnDefs
}
