import { sleep } from '../../../../helpers/helpers/sleep.js'
import { setElementHtml } from '../../../helpers/dom.js'

export function loadingFeedback () {
  let isActive = true

  const publicMethods = {
    init: async function () {
      await sleep(90000)

      if (!isActive) return

      setElementHtml('loadingMessage', 'Oops, it\'s taking longer than usual. Try refreshing the page.')
    },
    stop: function () {
      isActive = false
    }
  }

  publicMethods.init()
  return publicMethods
}
