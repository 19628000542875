import { apiEndpointCurrencyAssociate } from '../../../helpers/fx/settings.js'
import { callApi } from '../../helpers/fetch.js'

export async function associate (params, accountData) {
  const accountId = accountData._id

  const apiUrl = apiEndpointCurrencyAssociate
  const data = await callApi(apiUrl, {
    accountId,
    symbol: params.symbol,
    change: params.change
  }, {
    method: 'PUT'
  })
  accountData._accountCurrencies = data.accountCurrencies
  // console.log('accountData', accountData)
  return data
}
