import enableOptions from './enable.js'
import renderOptions from './render.js'

export default function optionsCurrenciesModule () {
  const data = {
    currencySymbol: null,
    htmlElement: null,
    allowedChoices: []
  }

  function setValue (value) {
    if (data.allowedChoices.includes(value)) data.currencySymbol = value
  }

  function getValue () {
    return data.currencySymbol
  }

  function enable (options, optionSelectorData) {
    return enableOptions(options, optionSelectorData, privateInstance)
  }

  function openModule (options, optionSelectorData) {
    return renderOptions(options, optionSelectorData, privateInstance)
  }

  const publicAPI = {
    enable,
    openModule,
    getValue,
    setValue
  }

  const privateInstance = {
    callbacks: {},
    api: publicAPI,
    data
  }

  return publicAPI
}
