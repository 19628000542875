
import { createElementFromHtml, renderElement } from '../../../helpers/dom.js'
import { fillElementHtmlId } from '../../../helpers/dom-app.js'
import addListeners from './add-listeners.js'
import htmlOptionsCurrencies from './options-currencies.html'
import htmlOneCurrency from './one-currency.html'
import { fillString } from '../../../../helpers/templating/string-template.js'
import { renderActive } from './render-active.js'

const CONTAINER_ID = 'optionSelectorContent'

export default function renderOptions (selectorOptions, optionSelectorData, privateInstance) {
  // console.log('renderOptions', selectorOptions, privateInstance)
  // console.log('current value', privateInstance.api.getValue())

  if (!privateInstance.data.htmlElement) {
    createModuleElement(privateInstance)
  }
  const moduleElement = privateInstance.data.htmlElement
  renderElement(CONTAINER_ID, moduleElement)

  renderActive(optionSelectorData, privateInstance)
}

/**
 * Creates the module DOM Element, and its listeners, without inserting it in the actual DOM.
 * @param {*} privateInstance
 * @returns
 */
function createModuleElement (privateInstance) {
  const elementData = fillElementHtmlId(htmlOptionsCurrencies)
  privateInstance.data.htmlElementId = elementData.id
  const htmlString = elementData.html

  const moduleElement = createElementFromHtml(htmlString)

  // The sub elements:
  privateInstance.data.allowedChoices.forEach(function (oneCurrencySymbol) {
    // console.log('oneCurrency', oneCurrencySymbol)
    const currencyHtml = fillString(htmlOneCurrency, {
      currencySymbol: oneCurrencySymbol
      // currencySymbol: oneCurrency.symbol
    })
    moduleElement.innerHTML += currencyHtml
  })

  privateInstance.data.htmlElement = moduleElement
  addListeners(privateInstance)

  return moduleElement
}
