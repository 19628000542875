
export function iterateMap (mapItem, leaveFunction) {
  const results = []

  function recurse (item) {
    // console.log('iterateMap', item)
    // console.log('item instanceof Map', item instanceof Map)
    if (item instanceof Map) {
      item.forEach(function (value) {
        recurse(value)
      })
    } else {
      // console.log('leaf: item', item)
      results.push(leaveFunction(item))
    }
  }

  recurse(mapItem)
  return results
}
