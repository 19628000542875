import { fillString } from '../../../../../helpers/templating/string-template.js'
import { setElementHtml } from '../../../../helpers/dom.js'
import { loadDisconnectXeroOrg } from '../../../xero/index.js'
import { loadCheckToken } from '../../../xero/organisations-checktoken.js'

const responseContainerId = 'tokenCheckResponse'

export function tokenListeners (orgId) {
  let checkTokenOngoing = false
  const checkTokenElement = document.getElementById('clickCheckToken')

  checkTokenElement.addEventListener('click', async function (clickEvent) {
    if (checkTokenOngoing) return

    checkTokenOngoing = true
    setElementHtml(responseContainerId, 'Checking')

    const data = await loadCheckToken(orgId)
    console.log('data', data)
    const tokenResultHtml = `
      <b>Working?:</b> :working
      <br>
      <b>Scope:</b> :scope
      <br>
      <b>apiQuotas:</b> :apiQuotas
    `

    const responseReady = fillString(tokenResultHtml, {
      working: data.tokenWorks?.work,
      scope: data.tokenWorks.tokenScope,
      apiQuotas: JSON.stringify(data?.tokenWorks?.apiHeadersInfo) || 'no info'
    })
    setElementHtml(responseContainerId, responseReady)

    checkTokenOngoing = false
  })

  let disconnectOngoing = false
  const disconnectElement = document.getElementById('clickDisconnect')
  disconnectElement.addEventListener('click', async function (clickEvent) {
    if (disconnectOngoing) return

    disconnectOngoing = true

    setElementHtml(responseContainerId, 'DISCONNECTING')

    const data = await loadDisconnectXeroOrg(orgId)
    console.log('data', data)

    setElementHtml(responseContainerId, 'Done? ' + data.response)

    disconnectOngoing = false
  })
}
