import { ascending } from 'd3'
import { makeTimeseries } from './make-timeseries.js'
import { d3Group } from './compute/d3-group.js'
import { iterateMap } from './compute/iterate-map.js'

export function makeRows (context) {
  console.log('makeRows', context)

  if (!context.data?.withContact) return

  // const analysisOption = 'topExpenses'
  // const analysisOption = 'newExpenses'
  // const analysisOption = 'endedExpenses'
  // const analysisOption = 'topRevenue'

  const analysisOption = context.analysisType
  const analysisParams = analysisOptions[analysisOption]
  const dataItems = filterDataItems(context.data?.withContact, analysisParams.filter)

  const monthsArray = makeTimeseries(context)

  const groupingParams = {
    groupBy: context.groupBy
  }

  const groupedMap = d3Group(dataItems, groupingParams, monthsArray, context)
  console.log('groupedMap', groupedMap)

  const uniqueRows = iterateMap(groupedMap,
    function (mapItem) {
      return mapItem
    }
  )
  console.log('uniqueRows', uniqueRows)

  // Top Expenses: pnl; positive value
  const filteredRows = filterRows(uniqueRows, analysisParams.filter)
  sortRows(filteredRows, analysisParams.sort)
  console.log('filteredRows', filteredRows)

  return filteredRows
}

/**
 * filter:
 *  statement: pnl, bs
 *  valueSign: 1 || -1
 *  isNew: true || false
 *  isEnded: true || false
 *
 *  TODO changeValue: 1 (increase), -1 (decrease), >X
 *  TODO valueMin: >X (manager multiplier?)
 * sort:
 *  valueSign: 1 || -1
 *  valueType: value, changeValue, changePct
 *  valuePeriod: total, lastPeriod
 *
 *  changeValue: increase, decrease, >X
 *  changePct: increase, decrease, >X
 *
 * Analysis by Value: specific period or total?
 * Analysis by Account type?
 */
const analysisOptions = {
  topExpenses: {
    // Top Expenses: pnl; positive value
    filter: {
      statement: 'pnl',
      valueSign: 1
    },
    sort: {
      valueSign: -1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },
  newExpenses: {
    filter: {
      statement: 'pnl',
      valueSign: 1,
      isNew: true
    },
    sort: {
      valueSign: -1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },
  endedExpenses: {
    filter: {
      statement: 'pnl',
      valueSign: 1,
      isEnded: true
    },
    sort: {
      valueSign: -1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },

  topRevenue: {
    // Top Revenue: pnl; negative value
    filter: {
      statement: 'pnl',
      valueSign: -1
    },
    sort: {
      valueSign: 1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },
  newRevenue: {
    filter: {
      statement: 'pnl',
      valueSign: -1,
      isNew: true
    },
    sort: {
      valueSign: 1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },
  endedRevenue: {
    filter: {
      statement: 'pnl',
      valueSign: -1,
      isEnded: true
    },
    sort: {
      valueSign: 1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  },
  allPnl: {
    filter: {
      statement: 'pnl'
    },
    sort: {
      valueSign: 1,
      valueType: 'value',
      valuePeriod: 'total'
    }
  }
}

function filterDataItems (dataItems, filters) {
  if (!filters.statement) return dataItems

  const filteredDataItems = dataItems.filter(function (dataItem) {
    let passFilters = true

    const statementMatch = dataItem.chartOfAccount?.statement === filters.statement
    passFilters = passFilters && statementMatch

    return passFilters
  })

  return filteredDataItems
}

/**
 *
 * @param {*} rows
 * @param {Object[]} filters
 * @returns
 */
function filterRows (rows, filters) {
  const filterRows = rows.filter(function (row) {
    let passFilters = true

    if (filters.valueSign) {
      const valueSignMatch = Math.sign(row.totalValue) === filters.valueSign
      passFilters = passFilters && valueSignMatch
    }
    if (filters.isNew) {
      const lastValue = row.values[row.values.length - 1]
      const hasValueAtLastPosition = Number.isFinite(lastValue) && lastValue !== 0

      // Define your threshold for what constitutes "new"
      const threshold = 1 // Example: Only allow rows with 1 or fewer non-zero values

      const isNewMatch = hasValueAtLastPosition && row.numberNonZeroValues <= threshold
      passFilters = passFilters && isNewMatch
    }
    if (filters.isEnded) {
      const lastValue = row.values[row.values.length - 1]

      // Define your threshold for what constitutes "new"
      const threshold = 1 // Example: Only allow rows with 1 or fewer non-zero values

      const isNewMatch = !lastValue && row.numberNonZeroValues >= threshold
      passFilters = passFilters && isNewMatch
    }
    return passFilters
  })

  return filterRows
}

/**
 *
 * @param {*} rows
 * @returns
 */
function sortRows (rows, sortParams) {
  // TODO: manage sortParams.valueType: 'value',
  // TODO: manage sortParams.valuePeriod: 'total', 'lastPeriod'
  const sortSign = sortParams.valueSign

  rows.sort(function (r1, r2) {
    return ascending(r1.totalValue * sortSign, r2.totalValue * sortSign)
  })
  return rows
}

/**
 *  New Expenses: pnl, positive, new in the last period
 *
*/
