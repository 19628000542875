import enableOptions from './enable.js'
import renderOptions from './render.js'

export default function optionsGroupByModule () {
  const data = {
    selected: [],
    htmlElement: null,
    allowedChoices: []
  }

  function setValue (value) {
    if (!Array.isArray(value)) return

    value.forEach(function (oneValue) {
      if (data.allowedChoices.map(oneChoice => oneChoice.value).includes(oneValue)) {
        data.selected.push(oneValue)
      }
    })
  }

  function toggleValue (value) {
    console.log('toggleValue', value, data.allowedChoices, data.selected)
    if (!data.allowedChoices.map(oneChoice => oneChoice.value).includes(value)) return

    if (!data.selected.includes(value)) {
      data.selected.push(value)
    } else {
      removeValue(value)
    }
    console.log('data.selected', data.selected)
  }
  function removeValue (value) {
    if (data.selected.indexOf(value) !== -1) {
      data.selected.splice(data.selected.indexOf(value), 1)
    }
  }

  function getValue () {
    return data.selected
  }

  function enable (options, optionSelectorData) {
    console.log('enable', options, optionSelectorData)
    return enableOptions(options, optionSelectorData, privateInstance)
  }

  function openModule (options, optionSelectorData) {
    console.log('openModule', options, optionSelectorData)
    return renderOptions(options, optionSelectorData, privateInstance)
  }

  const publicAPI = {
    enable,
    openModule,
    getValue,
    setValue,
    toggleValue
  }

  const privateInstance = {
    callbacks: {},
    api: publicAPI,
    data
  }

  return publicAPI
}
