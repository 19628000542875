import dayjs from 'dayjs'
import { formatNumber } from '../../../../../helpers/helpers/numbers.js'
import { appAccount } from '../../../../modules/user/account.js'
import { fillString } from '../../../../../helpers/templating/string-template.js'
import { makeXeroLink } from '../../../../helpers/xero-links.js'
import { linkToSource } from '../../../../helpers/link-to-source.js'
import { makeSourceName } from '../../../../helpers/make-source-name.js'

export default function makeColumnDefs (context) {
  const columnDefs = []

  columnDefs.push({
    headerName: 'Journal Date',
    valueGetter: function (cellParams) {
      const data = cellParams.data.journalDate
      if (!data) return null
      const date = dayjs(data)

      return date.format('YYYY-MM-DD')
    },
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Statement',
    valueGetter: function (cellParams) {
      const statement = cellParams.data.journalLine?.chartOfAccount?.statement
      return statement
    },
    pinned: 'left'
  })

  // Chart of Account
  columnDefs.push({
    headerName: 'Account',
    valueGetter: function (cellParams) {
      const coaAccount = cellParams.data.journalLine?.chartOfAccount
      if (!coaAccount) return

      return ['[', coaAccount.code, ']', ' ', coaAccount.name].join('')
    },
    cellRenderer: function (cellParams) {
      const outputTemplate = ':accountText [<a href=":urlToXero" target="_blank">Xero</a>]'

      // TODO: manage when multiple entities
      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      // const fromISOdate = dayjs(cellParams.data.journalDate).startOf('month').format('YYYY-MM-DD')
      // const toISOdate = dayjs(cellParams.data.journalDate).endOf('month').format('YYYY-MM-DD')

      const fromISOdate = dayjs(cellParams.data.journalDate).format('YYYY-MM-DD')
      const toISOdate = dayjs(cellParams.data.journalDate).format('YYYY-MM-DD')

      const urlToXero = makeXeroLink('ReportAccountTransactions', {
        shortCode,
        xeroAccountId: cellParams.data.journalLine?.chartOfAccount?.xeroAccountId,
        fromISOdate,
        toISOdate
      })

      return fillString(outputTemplate, {
        accountText: cellParams.value,
        urlToXero
      })
    },
    width: 200,
    pinned: 'left'
  })

  // Amount
  columnDefs.push({
    headerName: 'Amount',
    field: 'journalLine.netAmount',
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, '2')
    },
    type: 'numericColumn',
    pinned: 'left'
  })

  columnDefs.push({
    headerName: 'Recorded Date',
    valueGetter: function (cellParams) {
      const data = cellParams.data.xeroCreatedDate
      if (!data) return null
      const date = dayjs(data)

      return date.format('YYYY-MM-DD @ HH:mm:ss')
    }
    // width: 180
  })

  const trackingColDefs = includeTrackings(context)
  if (trackingColDefs) {
    columnDefs.push(...trackingColDefs)
  }

  // Contact Name
  columnDefs.push({
    headerName: 'Client/Vendor',
    valueGetter: function (cellParams) {
      const contactName = cellParams.data.contact?.name
      // if (!contactName) return null

      return contactName
    },
    cellRenderer: function (cellParams) {
      const contact = cellParams.data.contact
      if (!contact) return null

      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      const contactLinkUrl = makeXeroLink('Contact', {
        shortCode,
        xeroContactId: contact.xeroContactId
      })
      return fillString(':contactName [<a href=":contactLinkUrl" target="_blank">Xero</a>]', {
        contactName: contact.name,
        contactLinkUrl
      })
    }
  })

  columnDefs.push({
    headerName: 'Journal Type',
    valueGetter: function (cellParams) {
      return fillString(':xeroSourceType', {
        xeroSourceType: (cellParams.data.xeroSourceType || '')
      })
    }
  })

  columnDefs.push({
    headerName: 'Source Type',
    valueGetter: function (cellParams) {
      const source = cellParams.data.source
      if (!source) return null

      return fillString(':typeApi > :xeroSourceType', {
        typeApi: source.typeApi,
        xeroSourceType: (source.xeroSourceType || '')
      })
    }
  })

  // Source: eg. Sales Invoice: XXX
  columnDefs.push({
    headerName: 'Source',
    valueGetter: function (cellParams) {
      const source = cellParams.data.source
      if (!source) return null

      return makeSourceName(source)
    },
    cellRenderer: function (cellParams) {
      const source = cellParams.data.source
      if (!source) return null

      const shortCode = getEntityShortcode(cellParams.context.accountData, context.showOrgId)

      const mainSourceText = linkToSource(cellParams.data.source, shortCode, cellParams.data)

      if (!cellParams.data.source?.relatedSource) return mainSourceText

      const relatedSourceText = linkToSource(cellParams.data.source?.relatedSource, shortCode)
      return fillString(':mainSourceText for :relatedSourceText', {
        mainSourceText,
        relatedSourceText
      })
    },
    minWidth: 250
  })

  // Status
  // Eg. if transaction was reversed

  // How about multi currency?

  // Amount source currency (when multi currency); based on source?

  // Reference?
  columnDefs.push({
    field: 'reference'
  })

  // Description
  columnDefs.push({
    field: 'journalLine.description'
  })

  columnDefs.push({
    field: 'journalNumber',
    type: 'numericColumn'
  })

  columnDefs.push({
    headerName: 'Voided?',
    valueGetter: function (cellParams) {
      if (!cellParams.data.isVoided) return null
      return true// 'yes'
    }
  })

  // TODO: Is interco? related company?

  // TODO: Items?

  return columnDefs
}

function includeTrackings (context) {
  // console.log('includeTrackings', context)
  const accountData = appAccount.accountData()
  const trackingAccountData = accountData.trackingCategoriesData
  // console.log('trackingAccountData', trackingAccountData)

  // TODO: manage when multiple entities
  const trackingOfEntity = trackingAccountData.find(function (one) {
    return one.orgId === context.showOrgId
  })
  // console.log('trackingOfEntity', trackingOfEntity)

  if (!trackingOfEntity) return

  const colDefs = []

  trackingOfEntity?.categories?.forEach(function (oneCategory) {
    const colDef = {
      headerName: oneCategory.name,
      valueGetter: function (cellParams) {
        // console.log('cellParams', cellParams)

        // Sep 22nd 2024: the trackings is an object of the tracking categories
        const trackingOption = cellParams.data.journalLine?.trackings?.[oneCategory.name]
        return trackingOption

        // // Finding the tracking in Array
        // const targetTracking = cellParams.data.journalLine?.trackings?.find(function (one) {
        //   return oneCategory.name === one.category
        // })
        // // console.log('targetTracking', targetTracking)

        // if (!targetTracking) return

        // return targetTracking.name
      }
    }
    // console.log('colDef', colDef)

    colDefs.push(colDef)
  })
  // console.log('colDefs', colDefs)
  return colDefs
}

function getEntityShortcode (accountData, orgId) {
  const targetAccountOrg = accountData._accountOrganisations.find(function (one) {
    return one._xeroOrganisation?._id === orgId
  })
  const targetEntity = targetAccountOrg?._xeroOrganisation
  const shortCode = targetEntity.details?.shortCode

  return shortCode
}
