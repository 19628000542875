import dayjs from 'dayjs'
import { formatNumber } from '../../../../helpers/helpers/numbers.js'
import { makeTimeseries } from './make-timeseries.js'

export function makeColumnDefs (context) {
  const columnDefs = []

  const contactColumnDefs = makeContactColumnDefs(context)
  columnDefs.push(...contactColumnDefs)

  const accountColumnDefs = makeAccountColumnDefs(context)
  columnDefs.push(...accountColumnDefs)

  const entityColumnDefs = makeEntityColumnDefs(context)
  columnDefs.push(...entityColumnDefs)

  const monthsColumnDefs = makeMonthsColumnsDefs(context)
  columnDefs.push(...monthsColumnDefs)

  columnDefs.push({
    headerName: 'Total',
    valueGetter: function (cellParams) {
      const multiplier = -1 // Values are debit positive, we invert them
      return cellParams.data.totalValue * multiplier || 0
    },
    valueFormatter: function (cellParams) {
      return formatNumber(cellParams.value, context.decimals || 0)
    },
    cellClass: function (cellParams) {
      const classes = ['right', 'number']
      if (!cellParams.value) {
        classes.push('zero')
      }
      return classes
    },
    onCellClicked: function (cellParams) {
      console.log('cellParams', cellParams)
      console.log('colDef', cellParams.colDef)
      console.log('value', cellParams.value, cellParams.data.value)
    },
    width: 130,
    type: 'numericColumn',
    pinned: 'right'
  })

  return columnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeMonthsColumnsDefs (context) {
  console.log('makeMonthsColumnsDefs', context)

  const monthsArray = makeTimeseries(context)
  // console.log('monthsArray', monthsArray)

  const monthsColumnDefs = []
  monthsArray?.forEach(function (oneMonth) {
    monthsColumnDefs.push({
      headerName: dayjs(oneMonth, 'YYYY-MM').format('MMM YYYY'),
      valueGetter: function (cellParams) {
        const monthKey = cellParams.colDef.context.month

        let value = null

        if (context.showValueType === 'value') {
          const rowMonthlyMap = cellParams.data.monthly
          const accountContactMonthEntry = rowMonthlyMap.get(monthKey)
          value = accountContactMonthEntry?.value
        } else {
          const monthIndex = monthsArray.indexOf(monthKey)
          if (context.showValueType === 'changeValue') {
            value = cellParams.data.changeValue[monthIndex]
          } else if (context.showValueType === 'changePct') {
            value = cellParams.data.changePct[monthIndex]
          }
        }

        const multiplier = -1 // Values are debit positive, we invert them
        return value * multiplier || 0
      },
      valueFormatter: function (cellParams) {
        if (['value', 'changeValue'].includes(context.showValueType)) {
          return formatNumber(cellParams.value, context.decimals || 0)
        }
        if (context.showValueType === 'changePct') {
          const pct = cellParams.value * 100
          return formatNumber(pct, 1) + '%'
        }
      },
      cellClass: function (cellParams) {
        const classes = ['right', 'number']
        if (!cellParams.value) {
          classes.push('zero')
        }
        return classes
      },
      onCellClicked: function (cellParams) {
        console.log('cellParams', cellParams)
        console.log('colDef', cellParams.colDef)
        console.log('value', cellParams.value, cellParams.data.value)
      },
      type: 'numericColumn',
      width: 130,
      context: {
        month: oneMonth
      }
    })
  })

  return monthsColumnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeEntityColumnDefs (context) {
  // console.log('makeEntityColumnDefs', context.groupBy, context.groupBy.includes('entityKey'))
  const columnDefs = []

  if (context.groupBy.includes('entityKey')) {
    const columnDef = {
      headerName: 'Entity',
      valueGetter: function (cellParams) {
        const mapFirstEntry = cellParams.data.entities?.entries()?.next()?.value?.[1]?.[0]
        return mapFirstEntry?.name || '?? unknown'
      },
      // width: 200,
      pinned: 'left'
    }
    columnDefs.push(columnDef)
  } else {
    const columnDef = {
      headerName: 'Entities',
      valueGetter: function (cellParams) {
        return cellParams.data.entities?.size || '??'
      },
      width: 120,
      pinned: 'left'
    }
    columnDefs.push(columnDef)
  }
  return columnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeContactColumnDefs (context) {
  // console.log('makeContactColumnDefs', context.groupBy, context.groupBy.includes('contactKey'))
  const columnDefs = []

  if (context.groupBy.includes('contactKey')) {
    const columnDef = {
      headerName: 'Contact',
      valueGetter: function (cellParams) {
        const mapFirstEntry = cellParams.data.contacts?.entries()?.next()?.value?.[1]?.[0]
        return mapFirstEntry?.name || '?? unknown'
      },
      // width: 200,
      pinned: 'left'
    }
    columnDefs.push(columnDef)
  } else {
    const columnDef = {
      headerName: 'Contacts',
      valueGetter: function (cellParams) {
        return cellParams.data.contacts?.size || '??'
      },
      width: 120,
      pinned: 'left'
    }
    columnDefs.push(columnDef)
  }
  return columnDefs
}

/**
 *
 * @param {*} context
 * @returns
 */
function makeAccountColumnDefs (context) {
  // console.log('makeAccountColumnDefs', context.groupBy, context.groupBy.includes('accountKey'))
  const columnDefs = []

  if (context.groupBy.includes('accountKey')) {
    // columnDefs.push({
    //   headerName: 'Statement',
    //   valueGetter: function (cellParams) {
    //     return cellParams.data.chartOfAccount?.statement
    //   },
    //   // width: 80,
    //   pinned: 'left'
    // })

    // columnDefs.push({
    //   headerName: 'Type',
    //   valueGetter: function (cellParams) {
    //     return cellParams.data.chartOfAccount?.xeroType
    //   },
    //   // width: 100,
    //   pinned: 'left'
    // })

    // columnDefs.push({
    //   headerName: 'Code',
    //   valueGetter: function (cellParams) {
    //     return cellParams.data.chartOfAccount?.code
    //   },
    //   // width: 80,
    //   pinned: 'left'
    // })

    columnDefs.push({
      headerName: 'Account Name',
      valueGetter: function (cellParams) {
        // return cellParams.data.chartOfAccount?.name || '?? unknown'

        const mapFirstEntry = cellParams.data.chartOfAccounts?.entries()?.next()?.value?.[1]?.[0]
        return mapFirstEntry?.name || '?? unknown'
      },
      // width: 200,
      pinned: 'left'
    })
  } else {
    const columnDef = {
      headerName: 'Accounts',
      valueGetter: function (cellParams) {
        return cellParams.data.chartOfAccounts?.size || '??'
      },
      width: 120,
      pinned: 'left'
    }
    columnDefs.push(columnDef)
  }
  return columnDefs
}
