import dayjs from 'dayjs'
import { gridApi } from './update-view.js'

export function activateActions (parentElement) {
  // console.log('activateActions', parentElement)
  const elementsWithExport = parentElement.querySelector('[d-action="exportTable"]')
  activateExport(elementsWithExport)
}

function activateExport (element) {
  // console.log('activateExport', element)
  element.addEventListener('click', function () {
    if (!gridApi.exportDataAsCsv) return

    const exportFilename = [dayjs().format('YYYY-MM-DD HH:mm:ss'), ' With Contacts'].join('')

    gridApi.exportDataAsCsv({
      skipPinnedTop: true,
      fileName: exportFilename,
      processCellCallback: (params) => {
        // Ensure the values are not formatted; because 'useValueFormatterForExport'=false still returns the rounded values
        return params.value
      }
      // useValueFormatterForExport: false // Do not format numbers
    })
  })
}
